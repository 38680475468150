<script lang="ts" setup>
import type { Slide } from '~~/layers/api/models/Slide'

interface Props {
    slide: Slide;
    lazy: boolean;
}

const props = defineProps<Props>()
</script>

<template>
    <swiper-slide
        class="rounded-xl aspect-square md:aspect-[10/7] lg:aspect-[10/6] xl:aspect-[10/5] 2xl:aspect-[10/4.5] overflow-hidden relative"
        :lazy="props.lazy"
    >
        <NuxtImg
            :src="props.slide.image"
            :alt="props.slide.title"
            class="object-cover w-full h-full"
            :loading="props.lazy ? 'lazy' : 'eager'"
            sizes="300px sm:512px md:576px lg:783px xl:960px 2xl:1120px"
            placeholder
        />

        <div
            class="flex flex-col items-start gap-y-6 md:gap-y-12 absolute inset-0 p-7 md:p-16"
        >
            <TextHeading :level="1" :heading="false">
                {{ props.slide.title }}
            </TextHeading>

            <TextSubtitle>{{ props.slide.subtitle }}</TextSubtitle>

            <LinkColored
                v-if="props.slide.hyperlink"
                :to="props.slide.hyperlink"
            >
                {{ props.slide.action }}
            </LinkColored>
        </div>
    </swiper-slide>
</template>

<style scoped></style>
