<script lang="ts" setup></script>

<template>
    <Card>
        <img
            src="~/assets/images/cards/test.png?format=avif"
            alt="Пройти тест на выбор курса"
            class="absolute hidden lg:block h-full xl:h-[120%] top-0 right-0 2xl:right-20 xl:-top-12 2xl:-top-12"
            loading="lazy"
        >

        <div class="flex flex-col items-start justify-between gap-y-10">
            <div class="flex flex-col gap-y-6">
                <TextHeading :level="2" :heading="false">
                    Не можете выбрать курс?
                </TextHeading>

                <TextBody class="lg:max-w-md xl:max-w-xl">
                    Пройдите небольшой тест и мы подберем для вас подходящее
                    направление.
                </TextBody>
            </div>

            <LinkColored to="/courses/test" variant="primary-inverse-border">
                Пройти тест
            </LinkColored>
        </div>
    </Card>
</template>

<style scoped></style>
