<script lang="ts" setup>
const { newsService } = useApi()

const { data, pending, error } = useLazyAsyncData(() =>
  newsService.getLatest()
)

const news = computed(() => {
  if (!data.value) {
    return []
  }

  return data.value.data
})
</script>

<template>
    <section
        class="bg-uk-white p-8 lg:py-14 lg:px-16 flex flex-col items-start gap-6 lg:gap-12 rounded-xl"
    >
        <TextHeading :level="2">Специально для вас</TextHeading>

        <div class="w-full overflow-hidden scrollbar-none">
            <PreloadContainer :pending="pending" :error="error">
                <NewsHorizontalList :items="news" />
            </PreloadContainer>
        </div>

        <LinkColored to="/news" variant="primary-inverse-border">
            Перейти ко всем новостям
        </LinkColored>
    </section>
</template>

<style scoped></style>
