<script lang="ts" setup>
const { remoteConfig } = useConfigStore()

const locationUrl = computed(() => {
  const [lat, long] = [
    remoteConfig.CompanyAddressLat,
    remoteConfig.CompanyAddressLong,
  ]

  const yandexMapsUrl = `https://yandex.ru/maps/?ll=${long}%2C${lat}&mode=whatshere&whatshere%5Bpoint%5D=${long}%2C${lat}&whatshere%5Bzoom%5D=17&z=17`

  return yandexMapsUrl
})

const cleanPhoneNumber = computed(() =>
  remoteConfig.CompanyPhone?.replace(/\D/g, '')
)
</script>

<template>
    <Card>
        <div
            class="flex flex-col justify-start items-start gap-12 lg:w-2/3 2xl:w-1/2"
        >
            <div class="flex flex-col gap-6">
                <TextHeading :level="2">Как нас найти?</TextHeading>

                <TextBody>
                    {{ remoteConfig.CompanyAddress }} <br >
                    ({{ remoteConfig.CompanyAddressComment }})
                </TextBody>

                <div class="flex flex-col md:flex-row gap-4 md:gap-10">
                    <div class="flex flex-col min-w-fit">
                        <TextBody bold>Позвонить:</TextBody>
                        <NuxtLink external :to="`tel:${cleanPhoneNumber}`">
                            <TextBody>
                                <span class="zphone">
                                    {{ remoteConfig.CompanyPhone }}
                                </span>
                            </TextBody>
                        </NuxtLink>
                    </div>

                    <div class="flex flex-col min-w-fit">
                        <TextBody bold>Написать:</TextBody>
                        <NuxtLink
                            external
                            :to="`mailto:${remoteConfig.CompanyEmail}`"
                        >
                            <TextBody>
                                {{ remoteConfig.CompanyEmail }}
                            </TextBody>
                        </NuxtLink>
                    </div>

                    <div class="flex flex-col min-w-fit">
                        <TextBody bold>Режим работы:</TextBody>
                        <TextBody>
                            <!-- eslint-disable vue/no-v-html -->
                            <div v-html="remoteConfig.CompanyWorkingHours" />
                        </TextBody>
                    </div>
                </div>
            </div>

            <LinkColored
                :to="locationUrl"
                target="_blank"
                variant="primary-inverse-border"
            >
                Посмотреть на карте
            </LinkColored>
        </div>
    </Card>
</template>

<style scoped></style>
