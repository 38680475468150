<script lang="ts" setup>
const {slideService} = useApi()

const {data} = await useAsyncData(() => slideService.get())

const slides = computed(() => {
  if (!data.value) {
    return []
  }

  return data.value.data
})
</script>

<template>
  <ClientOnly>
    <section class="pt-4 lg:pt-12 text-white rounded-xl overflow-hidden">
      <swiper-container
        id="landing-slideshow"
        slides-per-view="1"
        autoplay="true"
        space-between="10"
        speed="1000"
        loop="true"
        :pagination="{ enabled: true, clickable: true }"
        disable-on-interaction="false"
      >
        <LandingSlideshowItem
          v-for="(slide, idx) in slides"
          :key="slide.title"
          :slide="slide"
          :lazy="idx !== 0"
        />
      </swiper-container>
    </section>
  </ClientOnly>
</template>

<style lang="scss" scoped>
#landing-slideshow::part(bullet) {
  background-color: #f1f1f1;
  opacity: 1;

  @media (min-width: 768px) {
    width: 0.75rem;
    height: 0.75rem;
  }
}

#landing-slideshow::part(bullet-active) {
  background-color: #fe6007;

  @media (min-width: 768px) {
    width: 0.75rem;
    height: 0.75rem;
  }
}
</style>
