<script lang="ts" setup>
// @ts-expect-error Vite plugin dynamic import
import Software from '~~/app/assets/images/about/software.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Teachers from '~~/app/assets/images/about/teachers.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Discounts from '~~/app/assets/images/about/discounts.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Syllabus from '~~/app/assets/images/about/syllabus.png?format=avif'

const { remoteConfig } = useConfigStore()

const yearsOpened = computed(() => {
  const currentYear = new Date().getFullYear()

  const openedYear = new Date(
    remoteConfig.CompanyEstablishedDate
  ).getFullYear()

  const years = currentYear - openedYear

  return years
})

const features = ref([
  {
    title: 'Новейшие версии программ',
    subtitle: 'Лучшая навигационная система',
    image: Software,
  },
  {
    title: 'Квалифицированные преподаватели',
    subtitle: 'Для Вас работают опытнейшие лоцманы',
    image: Teachers,
  },
  {
    title: 'Лояльная система оплаты и скидок',
    subtitle: 'Самая выгодная инвестиция в ваш карьерный рост',
    image: Discounts,
  },
  {
    title: 'Удобное, полезное и интересное обучение',
    subtitle:
            'Применимые знания, дистанционное обучение, и комфортное расписание',
    image: Syllabus,
  },
])
</script>

<template>
    <section class="flex flex-col gap-10 xl:py-20">
        <TextHeading :level="2" class="max-w-xl">
            Более {{ yearsOpened }} лет мы помогаем получить новую специальность
        </TextHeading>

        <div class="grid 2xl:grid-cols-2 gap-4">
            <figure
                v-for="feature in features"
                :key="feature.title"
                class="px-4 py-5 rounded-2xl bg-uk-white flex items-start gap-3 max-w-xl xl:max-w-none"
            >
                <img
                    :src="feature.image"
                    :alt="feature.title"
                    width="66"
                    height="66"
                    preload
                    class="shrink-0"
                >

                <figcaption class="flex flex-col gap-y-2 xl:w-3/4">
                    <TextSubtitle bold>{{ feature.title }}</TextSubtitle>
                    <TextBody>{{ feature.subtitle }}</TextBody>
                </figcaption>
            </figure>
        </div>
    </section>
</template>

<style scoped></style>
