<script lang="ts" setup>
import type { Swiper } from 'swiper'
// @ts-expect-error Vite plugin dynamic import
import Vodohod from '~~/app/assets/images/partners/16.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Lukoil from '~~/app/assets/images/partners/7.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Gazprom from '~~/app/assets/images/partners/5.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Transneft from '~~/app/assets/images/partners/11.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Inok from '~~/app/assets/images/partners/6.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import YugRusi from '~~/app/assets/images/partners/13.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Oteko from '~~/app/assets/images/partners/9.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Rosatom from '~~/app/assets/images/partners/21.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Pola from '~~/app/assets/images/partners/1.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import AGTU from '~~/app/assets/images/partners/2.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import VNII_RHO from '~~/app/assets/images/partners/3.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import VolzhskoeParokhodstvo from '~~/app/assets/images/partners/4.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Morwenna from '~~/app/assets/images/partners/8.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Rosnefteflot from '~~/app/assets/images/partners/10.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Fugro from '~~/app/assets/images/partners/12.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Saipem from '~~/app/assets/images/partners/14.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import Tamaneftgaz from '~~/app/assets/images/partners/15.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import AzovoDonskaya from '~~/app/assets/images/partners/17.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import KrasnodarskayaTamozhnya from '~~/app/assets/images/partners/18.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import RiverSea from '~~/app/assets/images/partners/19.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import RKVT from '~~/app/assets/images/partners/20.png?format=avif'
// @ts-expect-error Vite plugin dynamic import
import StepAgro from '~~/app/assets/images/partners/22.png?format=avif'

interface Partner {
    name: string;
    logo: string;
}

const swiperElement = ref<HTMLElement | null>(null)

const partners = ref<Partner[]>([
  {
    name: 'Водоход',
    logo: Vodohod,
  },
  {
    name: 'Лукойл',
    logo: Lukoil,
  },
  {
    name: 'Газпром',
    logo: Gazprom,
  },
  {
    name: 'Транснефть',
    logo: Transneft,
  },
  {
    name: 'Inok TM',
    logo: Inok,
  },
  {
    name: 'ЮГ Руси',
    logo: YugRusi,
  },
  {
    name: 'Отэко',
    logo: Oteko,
  },
  {
    name: 'Росатом',
    logo: Rosatom,
  },
  {
    name: 'Pola',
    logo: Pola,
  },
  {
    name: 'АГТУ',
    logo: AGTU,
  },
  {
    name: 'ВНИИ РХО',
    logo: VNII_RHO,
  },
  {
    name: 'Волжское пароходство',
    logo: VolzhskoeParokhodstvo,
  },
  {
    name: 'Morwenna',
    logo: Morwenna,
  },
  {
    name: 'Роснефтефлот',
    logo: Rosnefteflot,
  },
  {
    name: 'Fugro',
    logo: Fugro,
  },
  {
    name: 'Saipem',
    logo: Saipem,
  },
  {
    name: 'ТаманьНефтеГаз',
    logo: Tamaneftgaz,
  },
  {
    name: 'Администрация Азово-Донского бассейна',
    logo: AzovoDonskaya,
  },
  {
    name: 'Краснодарская таможня',
    logo: KrasnodarskayaTamozhnya,
  },
  {
    name: 'River Sea',
    logo: RiverSea,
  },
  {
    name: 'РК ВТ',
    logo: RKVT,
  },
  {
    name: 'Степь Агрохолдинг',
    logo: StepAgro,
  },
])

const swiperInstance = computed(() => {
  if (!swiperElement.value) {
    return null
  }

  // @ts-expect-error Swiper Element cannot be recognized by Vue
  return swiperElement.value.swiper as Swiper
})

function showPrevSlide() {
  if (!swiperInstance.value) {
    return
  }

    swiperInstance.value!.slidePrev()
}

function showNextSlide() {
  if (!swiperInstance.value) {
    return
  }

    swiperInstance.value!.slideNext()
}
</script>

<template>
    <section class="flex flex-col gap-12 xl:py-12">
        <div class="flex justify-between items-center">
            <TextHeading :level="2">
                Сотрудничаем с ведущими компаниями
            </TextHeading>

            <div class="hidden sm:block">
                <SlideSelector
                    @previous="showPrevSlide"
                    @next="showNextSlide"
                />
            </div>
        </div>

        <ClientOnly>
            <swiper-container
                id="partners-slider"
                ref="swiperElement"
                :grid="{
                    rows: 2,
                    fill: 'row',
                }"
                :breakpoints="{
                    0: {
                        slidesPerView: 2.5,
                        spaceBetween: 15,
                        scrollbar: {
                            enabled: true,
                            hide: false,
                        },
                    },
                    640: {
                        slidesPerView: 2.6,
                        spaceBetween: 10,
                        scrollbar: {
                            enabled: false,
                            hide: true,
                        },
                    },
                    1024: {
                        slidesPerView: 2.7,
                        spaceBetween: 10,
                        scrollbar: {
                            enabled: false,
                            hide: true,
                        },
                    },
                    1280: {
                        slidesPerView: 3.3,
                        spaceBetween: 10,
                        scrollbar: {
                            enabled: false,
                            hide: true,
                        },
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                        scrollbar: {
                            enabled: false,
                            hide: true,
                        },
                    },
                }"
                speed="200"
                class="w-full"
            >
                <swiper-slide
                    v-for="(partner, idx) in partners"
                    :key="idx"
                    class="rounded-xl bg-uk-white px-2 md:py-2 md:px-8"
                    lazy="true"
                >
                    <div class="flex h-20 md:h-36">
                        <img
                            :src="partner.logo"
                            :alt="partner.name"
                            width="200"
                            loading="lazy"
                            class="w-full h-full object-contain"
                        >
                    </div>
                </swiper-slide>
            </swiper-container>
        </ClientOnly>
    </section>
</template>

<style lang="scss" scoped></style>
