<script lang="ts" setup></script>

<template>
    <Card>
        <img
            src="~/assets/images/cards/percentage.png?format=avif"
            alt="Акции"
            class="absolute hidden lg:block h-[120%] -top-5 -right-10 xl:h-[150%] xl:-right-14 2xl:right-6 xl:-top-20 2xl:-top-24"
            loading="lazy"
        >

        <div class="flex flex-col items-start justify-between gap-y-10">
            <div class="flex flex-col gap-y-6">
                <TextHeading :level="2" :heading="false">Акции</TextHeading>
                <TextBody>
                    Посмотрите наши лучшие предложения и скидки.
                </TextBody>
            </div>

            <LinkColored to="/promotions" variant="primary-inverse-border">
                Узнать подробнее
            </LinkColored>
        </div>
    </Card>
</template>

<style scoped></style>
